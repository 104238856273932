.Modal {
    position: fixed;
    z-index: 700;
    background-color: white;
    width: 80%;
    border: 1px solid #ccc;
    box-shadow: 1px 1px 1px black;
    padding: 25px 16px;
    left: 10%;
    top: 30%;
    box-sizing: border-box;
    transition: all 0.3s ease-out;
}
.Modal.center{
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 80vH;
    overflow: auto;
}

.Modal .exit-container{
    text-align: right;
}
.Modal .exit-container .fa-window-close{
    cursor: pointer;
    font-size: 1.7rem;
}
.Modal .exit-container .fa-window-close:hover{
    transform: scale(1.03);
}

@media (min-width: 600px) {
    .Modal {
        width: 700px;
        left: calc(50% - 350px);
    }
    .Modal.center{
        width: 900px;
    }
}