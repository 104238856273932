@import url('https://edge.conference2web.com/globals/fonts/Roboto/font.css');
@import url('https://edge.conference2web.com/globals/fonts/OpenSans/font.css');

:root {
  --app-height:100vh;

  --basic-dark: #001A38;
  --basic-dark-20: rgba(0, 26, 56, .2);
  --basic-dark-50: rgba(0, 26, 56, .5);
  --basic-dark-90: rgba(0, 26, 56, .9);
  --main-black: #000000;
  --navy-blue: #89BBF4;

  --basic-green: #04CC0C;

  --basic-red: #D9004B;
  --dark-red: #dc3545;
  --leave-red: #D90101;

  --basic-gray: #F6F6F8;
  --basic-gray-dark-30: rgba(178, 186, 195, .3);

  --fishbowl-main: #4C5B7D;
  --fishbowl-main-hover: #5872ad;


}

body {
  background: #fff;
  color: var(--main-black);
  font-family: 'Open Sans', sans-serif;
}

.me-audio-only-chime,
#modal{
  .btn{ border-radius: 1.2rem !important;}
  .btn-dark,
  .btn-black{
    background-color: var(--main-black);
  }
  .btn-black{
    border-radius: 1.2rem !important;
    height: 46px;
    font-weight: bold;
    font-size: 1.2rem;
    color: #fff;
  }

  .val-error-input {
    border:1px solid  var(--basic-red);
  }
  .val-error-text {
    color: var(--basic-red);
  }

  .opacity07{
    opacity: .7;
  }
  .opacity03{
    opacity: .3;
  }
}

.me-audio-only-chime{
  padding: .5rem 1rem;
  max-width: 450px;
  height: var(--app-height, 100vh);
  // height: 100svh;
  display: flex;
  flex-direction: column;

  &__username-form{
    margin-top: 2rem;
    font-size: 1.1rem;
    > div{
      margin-bottom: 1.2rem;
    }
    .previos-meetings-msg{
      display: flex;
      flex-direction: column;
      > *{
        padding-bottom: .25rem;
      }
      .me-form-control{
        position: relative;
        display: flex;
        .lblChange{
          font-size: .85rem;
          right: .25rem;
          display: flex;
          align-items: center;
          color: var(--basic-dark);
          text-decoration: underline;
          cursor: pointer;
          &:hover{
            transform: scale(1.05);
          }
          i{
            margin-right: .1rem;
          }
        }
      }
    }
  }

  &__session-title {
    font-size: 1.3rem;
    font-weight: bold;
    max-height: 4rem;
    flex: 0 0 2rem;
    margin-bottom: 1rem;
  }

  &__sponsor-container {
    // height: 70vH;
    flex-grow: 1;
    min-height: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover
    }
  }

  &__controls {
    margin-top: 1rem;
    border: 2px solid var(--main-black);
    border-radius: 1.2rem;
    height: 47px;
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    overflow: hidden;

    .mute-container {
      background-color: var(--main-black);
      padding: 0.25rem 0.5rem;
      height: 100%;
      border-radius: 1rem;
      flex: 0 1 85px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.5s ease;
      &.is-muted{
        opacity: .87;
        box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
        position: relative;
        
        &::after{
          position: absolute;
          content: "";
          height: 3px;
          width: 45px;
          background-color: rgba(255,255,255,.87);
          z-index: 10;
          transform: rotate(-45deg);
          transform-origin: 52% 0%;
        }
      }
    }

    .volume-controls{
      
      height: 100%;
      padding: 0 1rem;
      flex: 1 0 auto;
      display: flex;
      align-items: center;
      .range-container{
        flex: 1 0 auto;
        margin:5px 1rem 0 1rem;
        .custom-range::-webkit-slider-thumb{  
          background-color: #fff;
          border: 1px solid var(--main-black);
          width: 25px;
          height: 25px;
          margin-top: -.6rem;

        }
        .custom-range::-webkit-slider-runnable-track{  
          background-color:var(--main-black);
          height: .25rem;
        }

      }

    }
  }

  &__leave-container{
    margin-top: auto;
    margin-bottom: .75rem;
    padding-top: 1rem;
    .btn{
      background-color: var(--main-black);
      border-radius: 1.2rem !important;
      height: 46px;
      font-weight: bold;
      font-size: 1.2rem;
    }
  }


  .btn-action{
    cursor: pointer;
    &:hover{
      img{
        transform: scale(1.1);
      }
    }
    &.me-disabled{
      pointer-events: none;
      img{
        opacity: .3;
      }
    }
  }

  .progress-bar{ background-color: var(--basic-dark-50);}
  
}


.text-info{
  color: var(--basic-dark) !important;
}

.hide{
  display: none !important;
}

.line-clamp-1 {
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-2 {
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-3 {
  display: -webkit-box !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}