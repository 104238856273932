.camera-permission-screen{
    font-size: 1.1rem;;
}

.camera-permission-screen img.arrow{
    position: absolute;
    z-index: 100;
    top: 127px;
    left: 290px;
}

.camera-permission-screen .content{
    text-align: center;
    color: #fff;
    z-index: 1001;
    position: absolute;
    top: 265px;
    left: 50%;
    transform: translate(-50%, 0);
}

.camera-permission-screen.blocked img.arrow{
    top: 0;
    left: 110px;
}
.camera-permission-screen.blocked .content{
    top:205px;
    transform: translate(-50%, 0);
}

.camera-permission-screen.firefox img.arrow{
    top: 163px;
    left: 323px;
}
.camera-permission-screen.blocked.firefox img.arrow{
    top: 0;
    left: 225px;
}


.help-info-content{
    text-align: left;
    margin-top:3.3rem;
}
.help-info-content .heading{
    font-size:1.7rem;
    font-weight: bold;;
}
.help-info-content a{
    color:#fff;
    text-decoration: underline;
}