

.user-info-screen form{
    position: absolute;
    width: 80%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 101;
}

.user-info-screen div.card{
    text-align: center;
}


.user-info-screen .card-text{
    font-size: 1.2rem;
}
@media (min-width: 600px) {
    .user-info-screen form{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 101;
        width: auto;
    }
}